<template>
  <c-box py="20px">
    <c-flex
      align-items="center"
      :flex-dir="['column', 'row']"
    >
      <c-text
        flex="1"
        font-size="xl"
      >
        Result Progress & Indikator
      </c-text>
      <c-box
        w="100%"
        max-w="260px"
      >
        <c-select
          v-model="chartMode"
          w="fit-content"
        >
          <option value="graphic">
            Grafik
          </option>
          <option value="photo">
            Foto
          </option>
        </c-select>
      </c-box>
    </c-flex>
    <c-flex
      v-if="chartMode === 'graphic'"
      mt="20px"
      h="573px"
      border-color="primary.400"
      border-width="1px"
      px="25px"
      py="20px"
      flex-dir="column"
    >
      <c-flex w="full">
        <c-box
          flex="1"
          w="full"
        />
        <c-box w="260px">
          <c-select
            v-model="chartFilter"
            w="260px"
          >
            <option value="weight">
              Berat Badan
            </option>
            <option value="waist">
              Lingkar Pinggang
            </option>
            <option value="bodyFat">
              Persen Lemak
            </option>
            <option value="muscleMass">
              Massa Otot
            </option>
            <option value="bodyWater">
              Cairan Tubuh
            </option>
            <option value="visceralFat">
              Lemak Visceral
            </option>
            <option value="boneMass">
              Massa Tulang
            </option>
            <option value="BMR">
              Body Mass Ratio
            </option>
          </c-select>
        </c-box>
      </c-flex>
      <c-box mt="70px">
        <Charts
          v-if="chartFilter !== 'photo'"
          key="current-chart"
          :chart-data="chartData"
        />
        <c-box
          v-else
          display="flex"
          overflow-x="scroll"
          max-w="850px"
        >
          <c-box
            v-for="item in progressList"
            :key="item.id"
            d="flex"
            flex-dir="column"
            align-items="center"
          >
            <c-box
              h="270px"
              w="200px"
              bg="gray"
              mx="5px"
              d="flex"
              align-items="center"
              justify-content="center"
            >
              <a
                :href="item.progressPhoto"
                target="_blank"
                rel="noreferrer noopener"
              >
                <img
                  v-chakra
                  :src="item.progressPhoto"
                >
              </a>
            </c-box>
            <c-box
              mt="30px"
              pos="relative"
              w="full"
              d="flex"
              align-items="center"
              justify-content="center"
              border-top-width="2px"
              border-top-color="#008C8136"
            >
              <c-box
                pos="absolute"
                top="-15px"
                h="30px"
                w="30px"
                border-width="10px"
                border-color="white"
                rounded="full"
                bg="primary.400"
              />
              <c-text mt="20px">
                {{ item.date | formatDate('DD MMM') }}
              </c-text>
            </c-box>
          </c-box>
        </c-box>
      </c-box>
    </c-flex>
    <c-flex
      v-else
      mt="20px"
      border-color="primary.400"
      border-width="1px"
      px="25px"
      py="20px"
      flex-dir="column"
    >
      <c-box
        display="flex"
        pb="20px"
        overflow-x="auto"
        max-w="850px"
      >
        <c-box
          v-for="item in progressList"
          :key="item.id"
          d="flex"
          flex-dir="column"
          align-items="center"
        >
          <c-box
            h="270px"
            w="200px"
            bg="gray"
            mx="5px"
            d="flex"
            align-items="center"
            justify-content="center"
          >
            <a
              :href="item.progressPhoto"
              target="_blank"
              rel="noreferrer noopener"
            >
              <img
                v-chakra
                :src="item.progressPhoto"
              >
            </a>
          </c-box>
          <c-box
            mt="30px"
            pos="relative"
            w="full"
            d="flex"
            align-items="center"
            justify-content="center"
            border-top-width="2px"
            border-top-color="#008C8136"
          >
            <c-box
              pos="absolute"
              top="-15px"
              h="30px"
              w="30px"
              border-width="10px"
              border-color="white"
              rounded="full"
              bg="primary.400"
            />
            <c-text mt="20px">
              {{ item.date | formatDate("DD MMM") }}
            </c-text>
          </c-box>
        </c-box>
      </c-box>
    </c-flex>
    <c-flex
      mt="30px"
      flex-dir="column"
    >
      <c-text>History progress & tracker hasil pengisian Klien</c-text>
      <hr
        v-chakra
        w="full"
        mt="10px"
      >

      <c-flex
        mt="20px"
        flex-dir="column"
      >
        <CardProgress
          v-for="item in progressList"
          :id="item.id"
          :key="item.id"
          :date="item.date"
          :weight="item.overview.weight"
          :waist="item.overview.waist"
          :body-fat="item.overview.bodyFat"
          :muscle-mass="item.overview.muscleMass"
          :body-water="item.overview.bodyWater"
          :visceral-fat="item.overview.visceralFat"
          :bone-mass="item.overview.boneMass"
          :bmr="item.overview.BMR"
          @click="goToDetail($event)"
        />
      </c-flex>
    </c-flex>
  </c-box>
</template>

<script >
import Charts from '@/components/chart';
import CardProgress from '@/views/nutritionists/progress-tracker/indikator/card-progress';
import { computed, ref, inject } from '@vue/composition-api';
import { getChartData } from '@/utils/get-chart-data';
import { useActions } from '@/stores';
import { useRoute } from '@/utils/use-route';
import { useEqualWatch } from '@/views/client/progress-tracker/forms/helper-mixin';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const $router = inject('$router');
  const clientId = computed(() => route.value?.params?.clientId);
  const progressList = ref([]);
  const chartFilter = ref('bodyFat');
  const chartMode = ref('graphic');
  const chartData = computed(() => {
    return getChartData(progressList.value, chartFilter.value);
  });
  const {
    getProgressForClientId
  } = useActions('nutriProgressTracker', ['getProgressForClientId']);
  useEqualWatch(clientId, async clientId => {
    progressList.value = await getProgressForClientId(clientId);
  });

  function goToDetail(id) {
    $router.push({
      name: 'nutri.progress-tracker.detail',
      params: {
        clientId: clientId.value,
        progressId: id
      }
    });
  }

  return {
    progressList,
    chartFilter,
    chartMode,
    chartData,
    goToDetail
  };
};

__sfc_main.components = Object.assign({
  Charts,
  CardProgress
}, __sfc_main.components);
export default __sfc_main;
</script>
