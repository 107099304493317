var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "py": "20px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "flex-dir": ['column', 'row']
    }
  }, [_c('c-text', {
    attrs: {
      "flex": "1",
      "font-size": "xl"
    }
  }, [_vm._v(" Result Progress & Indikator ")]), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-w": "260px"
    }
  }, [_c('c-select', {
    attrs: {
      "w": "fit-content"
    },
    model: {
      value: _vm.chartMode,
      callback: function callback($$v) {
        _vm.chartMode = $$v;
      },
      expression: "chartMode"
    }
  }, [_c('option', {
    attrs: {
      "value": "graphic"
    }
  }, [_vm._v(" Grafik ")]), _c('option', {
    attrs: {
      "value": "photo"
    }
  }, [_vm._v(" Foto ")])])], 1)], 1), _vm.chartMode === 'graphic' ? _c('c-flex', {
    attrs: {
      "mt": "20px",
      "h": "573px",
      "border-color": "primary.400",
      "border-width": "1px",
      "px": "25px",
      "py": "20px",
      "flex-dir": "column"
    }
  }, [_c('c-flex', {
    attrs: {
      "w": "full"
    }
  }, [_c('c-box', {
    attrs: {
      "flex": "1",
      "w": "full"
    }
  }), _c('c-box', {
    attrs: {
      "w": "260px"
    }
  }, [_c('c-select', {
    attrs: {
      "w": "260px"
    },
    model: {
      value: _vm.chartFilter,
      callback: function callback($$v) {
        _vm.chartFilter = $$v;
      },
      expression: "chartFilter"
    }
  }, [_c('option', {
    attrs: {
      "value": "weight"
    }
  }, [_vm._v(" Berat Badan ")]), _c('option', {
    attrs: {
      "value": "waist"
    }
  }, [_vm._v(" Lingkar Pinggang ")]), _c('option', {
    attrs: {
      "value": "bodyFat"
    }
  }, [_vm._v(" Persen Lemak ")]), _c('option', {
    attrs: {
      "value": "muscleMass"
    }
  }, [_vm._v(" Massa Otot ")]), _c('option', {
    attrs: {
      "value": "bodyWater"
    }
  }, [_vm._v(" Cairan Tubuh ")]), _c('option', {
    attrs: {
      "value": "visceralFat"
    }
  }, [_vm._v(" Lemak Visceral ")]), _c('option', {
    attrs: {
      "value": "boneMass"
    }
  }, [_vm._v(" Massa Tulang ")]), _c('option', {
    attrs: {
      "value": "BMR"
    }
  }, [_vm._v(" Body Mass Ratio ")])])], 1)], 1), _c('c-box', {
    attrs: {
      "mt": "70px"
    }
  }, [_vm.chartFilter !== 'photo' ? _c('Charts', {
    key: "current-chart",
    attrs: {
      "chart-data": _vm.chartData
    }
  }) : _c('c-box', {
    attrs: {
      "display": "flex",
      "overflow-x": "scroll",
      "max-w": "850px"
    }
  }, _vm._l(_vm.progressList, function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "d": "flex",
        "flex-dir": "column",
        "align-items": "center"
      }
    }, [_c('c-box', {
      attrs: {
        "h": "270px",
        "w": "200px",
        "bg": "gray",
        "mx": "5px",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center"
      }
    }, [_c('a', {
      attrs: {
        "href": item.progressPhoto,
        "target": "_blank",
        "rel": "noreferrer noopener"
      }
    }, [_c('img', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "src": item.progressPhoto
      }
    })])]), _c('c-box', {
      attrs: {
        "mt": "30px",
        "pos": "relative",
        "w": "full",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "border-top-width": "2px",
        "border-top-color": "#008C8136"
      }
    }, [_c('c-box', {
      attrs: {
        "pos": "absolute",
        "top": "-15px",
        "h": "30px",
        "w": "30px",
        "border-width": "10px",
        "border-color": "white",
        "rounded": "full",
        "bg": "primary.400"
      }
    }), _c('c-text', {
      attrs: {
        "mt": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.date, 'DD MMM')) + " ")])], 1)], 1);
  }), 1)], 1)], 1) : _c('c-flex', {
    attrs: {
      "mt": "20px",
      "border-color": "primary.400",
      "border-width": "1px",
      "px": "25px",
      "py": "20px",
      "flex-dir": "column"
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "pb": "20px",
      "overflow-x": "auto",
      "max-w": "850px"
    }
  }, _vm._l(_vm.progressList, function (item) {
    return _c('c-box', {
      key: item.id,
      attrs: {
        "d": "flex",
        "flex-dir": "column",
        "align-items": "center"
      }
    }, [_c('c-box', {
      attrs: {
        "h": "270px",
        "w": "200px",
        "bg": "gray",
        "mx": "5px",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center"
      }
    }, [_c('a', {
      attrs: {
        "href": item.progressPhoto,
        "target": "_blank",
        "rel": "noreferrer noopener"
      }
    }, [_c('img', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra"
      }],
      attrs: {
        "src": item.progressPhoto
      }
    })])]), _c('c-box', {
      attrs: {
        "mt": "30px",
        "pos": "relative",
        "w": "full",
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "border-top-width": "2px",
        "border-top-color": "#008C8136"
      }
    }, [_c('c-box', {
      attrs: {
        "pos": "absolute",
        "top": "-15px",
        "h": "30px",
        "w": "30px",
        "border-width": "10px",
        "border-color": "white",
        "rounded": "full",
        "bg": "primary.400"
      }
    }), _c('c-text', {
      attrs: {
        "mt": "20px"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(item.date, "DD MMM")) + " ")])], 1)], 1);
  }), 1)], 1), _c('c-flex', {
    attrs: {
      "mt": "30px",
      "flex-dir": "column"
    }
  }, [_c('c-text', [_vm._v("History progress & tracker hasil pengisian Klien")]), _c('hr', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra"
    }],
    attrs: {
      "w": "full",
      "mt": "10px"
    }
  }), _c('c-flex', {
    attrs: {
      "mt": "20px",
      "flex-dir": "column"
    }
  }, _vm._l(_vm.progressList, function (item) {
    return _c('CardProgress', {
      key: item.id,
      attrs: {
        "id": item.id,
        "date": item.date,
        "weight": item.overview.weight,
        "waist": item.overview.waist,
        "body-fat": item.overview.bodyFat,
        "muscle-mass": item.overview.muscleMass,
        "body-water": item.overview.bodyWater,
        "visceral-fat": item.overview.visceralFat,
        "bone-mass": item.overview.boneMass,
        "bmr": item.overview.BMR
      },
      on: {
        "click": function click($event) {
          return _vm.goToDetail($event);
        }
      }
    });
  }), 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }